<template>
    <div>
        <CRow>
            <CCol md="12">
                <CCard>
                    <CCardHeader>
                        <CRow>
                            <CCol md="4">
                                Detail Laporan Realisasi
                            </CCol>
                        </CRow>
                    </CCardHeader>
                    <CCardBody>
                            <table class="w-100" border="0">
                                <tr>
                                    <th style="width: 250px">Nomor SIP </th>
                                    <td style="width: 15px"> : </td>
                                    <td>{{detailDataRealisasi.prs_nomor_sk}} </td>
                                </tr>
                                <tr>
                                    <th>Tanggal Pengajuan</th>
                                    <td style="width: 15px"> : </td>
                                    <td>{{detailDataRealisasi.prs_tanggal}} </td>
                                </tr>
                                <tr>
                                    <th>Perihal</th>
                                    <td style="width: 15px"> : </td>
                                    <td>{{detailDataRealisasi.prs_perihal}} </td>
                                </tr>
                                <tr>
                                    <th>Data Benih</th>
                                    <td style="width: 15px"> : </td>
                                    <td>Data Benih Untuk Realisasi</td>
                                </tr>
                                <tr>
                                    <td colspan="3">
                                        <table class="table table-bordered table-sm w-100" id="table-benih">
                                            <thead class="bg-sapphire">
                                                <tr>
                                                    <th style="width: 15px">No</th>
                                                    <th>Jenis Tanaman</th>
                                                    <th>Nama Latin</th>
                                                    <th>Varietas/Klon/Hibrida</th>
                                                    <th>Bentuk Benih</th>
                                                    <th>Jumlah</th>
                                                    <th>Jumlah Realisasi</th>
                                                    <th>Satuan</th>
                                                    <th>Nilai (Total Harga dalam USD)</th>
                                                    <th>Kode HS</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(value_benih, index_benih) in detailDataRealisasi.permohonan_realisasi_benih" :key="index_benih">
                                                    <td>{{index_benih+1}}</td>
                                                    <td>{{value_benih.tanaman.t_nama}}</td>
                                                    <td>{{value_benih.prsb_nama_latin}}</td>
                                                    <td>{{value_benih.prsb_varietas}}</td>
                                                    <td>{{value_benih.bentuk_benih.bb_nama}}</td>
                                                    <td>{{value_benih.prsb_jumlah}}</td>
                                                    <td>{{value_benih.prsb_jumlah_realisasi}}</td>
                                                    <td>{{value_benih.satuan.s_nama}}</td>
                                                    <td>{{value_benih.prsb_nilai_total}}</td>
                                                    <td>{{value_benih.prsb_kode_hs}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                
                            </table>
                        </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>
<script>
    const datalaporan = [];
    export default {
        name: "DetailRealisasi",
        
        data() {
            return {
                idrealisasi: this.$route.params.idrealisasi,
                detailDataRealisasi: datalaporan,
            };
        },
        methods: {
        },
        beforeCreate() {
            Swal.fire({
                title: '<i class="fa fa-refresh fa-spin"></i>',
                text: "Mohon tunggu sebentar...",
                showConfirmButton: false,
                allowOutsideClick: false,
            });
            let session_data = JSON.parse(this.session);
            let self = this;
            axios
                .get(this.apiLink + "api/permohonan_realisasi/permohonan_all", {
                    params: {
                        id: this.$route.params.idrealisasi,
                    },
                    headers: {
                        Authorization: "Bearer " + this.access_token,
                    },
                })
                .then((response) => {
                    var res_realisasi = response.data;
                    var res_realisasi_data = res_realisasi.data.data;
                    console.log(res_realisasi_data);
                    if (res_realisasi.meta.code != "200") {
                        Swal.fire({
                            icon: "error", 
                            title: "Oops...",
                            text: res_realisasi.data.message,
                        });
                    } else {
                        // let raw_realisasi = [];
                        // $.each(res_realisasi_data, function(index_realisasi, val_realisasi) {
                        //      $.each(val_realisasi.permohonan_realisasi_benih, function(index_benih, val_benih) {
                        //         val_benih.realisasi = val_realisasi;
                        //         raw_realisasi.push(val_benih);
                        //      });
                        // });
                        // console.log(raw_realisasi);
                        // var detailData = res_realisasi_data.data.find(detailDatas => {
                        //     if (detailDatas.id === self.idrealisasi) {
                        //         return detailDatas
                        //     }
                        // })
                        // alert(this.idrealisasi);
                        this.detailDataRealisasi = res_realisasi_data.data[0];
                        console.log(this.detailDataRealisasi);
                        Swal.close();
                        // this.paging = res_realisasi_data.links;
                        // this.show_data_from = res_realisasi_data.from;
                        // this.show_data_to = res_realisasi_data.to;
                        // this.total_data = res_realisasi_data.total;
                        // this.curent_page = res_realisasi_data.current_page;
                    }
                });
        },
        mounted() {
            $('.no-readonly').prop('readonly', false);
        }
    };
</script>